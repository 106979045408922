import React, { useContext } from "react";
import SEO from "@src/components/SEO";
import SchoolsHeroSection from "@src/components/SchoolsHeroSection";
import CTASection from "@src/components/CTASection";
import { Title, Space, ListItem } from "@src/components/nessie-web";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import { mediaQueries } from "@src/styles/theme";
import { Box, Flex } from "@src/components/Boxes";
import Container from "@src/components/Container";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { css } from "@emotion/react";
import MarkedTranslate from "@src/components/translation/MarkedTranslate";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";

const ItemBox = styled("div")`
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto 66px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(201, 201, 201, 0.5);
  border-radius: 5px;

  width: 100%;
  ${mediaQueries[1]} {
    width: 83%;
  }
`;

const PBISFeaturesList = styled.div`
  ${mediaQueries[0]} {
    margin-left: 80px;
  }

  ${mediaQueries[1]} {
    margin-left: 20px;
  }

  ${mediaQueries[2]} {
    margin-left: 20px;
  }
`;

const itemBoxHeaderCss = css`
  h2 {
    font-size: 24px;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 22px;
    margin-top: 0;
  }
`;

const PhoneRightImg = styled(GatsbyImageWrapper)`
  position: relative !important;
  left: auto;
  right: auto;
  bottom: 0;
  width: 100%;
  max-width: 170px;
  ${mediaQueries[0]} {
    position: absolute !important;

    max-width: 200px;
    right: 34px;
  }
  ${mediaQueries[1]} {
    max-width: 299px;
    right: 50px;
  }
`;

const PhoneLeftImg = styled(GatsbyImageWrapper)`
  position: relative !important;
  left: auto;
  right: auto;
  bottom: 0;
  width: 100%;
  max-width: 170px;
  ${mediaQueries[0]} {
    position: absolute !important;

    max-width: 200px;
    left: 14px;
  }
  ${mediaQueries[1]} {
    max-width: 299px;
    left: 30px;
  }
`;

const MojoImg = styled(GatsbyImageWrapper)`
  display: none;
  ${mediaQueries[0]} {
    display: block;
    position: absolute !important;
    display: block;
    position: absolute;
    bottom: -38px;
    left: 26px;
    max-width: 137px;
    width: 100%;
  }
`;

const ListItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ListItemCheck = styled.img`
  margin-top: 6px;
  margin-right: 9px;
`;

function Pbis() {
  const data = useStaticQuery(graphql`
    {
      directus {
        common_data_schools {
          hero_image_url
          hero_image_mobile_url
          classdojo_school_check_url
          cta_background_image_url
        }
        page_schools {
          hero_button_url
          cta_button_url
        }
        page_pbis {
          pbis_features_list
          feature_one_image {
            filename_disk
          }
          feature_two_image {
            filename_disk
          }
          feature_three_image {
            filename_disk
          }
        }
      }
      explaining: file(name: { eq: "Explaining@2x" }, relativePath: { regex: "/pbis/" }) {
        childImageSharp {
          gatsbyImageData(width: 240, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);

  const t = useContext(TranslationContext);

  const {
    explaining,
    directus: {
      common_data_schools: {
        hero_image_url,
        hero_image_mobile_url,
        classdojo_school_check_url,
        cta_background_image_url,
      },
      page_schools: { hero_button_url, cta_button_url },
      page_pbis: { pbis_features_list, feature_one_image, feature_two_image, feature_three_image },
    },
  } = data;

  const SchoolsHeroProps = {
    hero_title: "directus.page_pbis.hero_title",
    hero_text: "directus.page_pbis.hero_text",
    hero_button_text: "directus.common_data_schools.hero_button_text",
    hero_button_advice: "directus.common_data_schools.hero_button_advice",
    hero_image_url,
    hero_image_alt: "directus.common_data_schools.hero_image_alt",
    hero_image_mobile_url,
    hero_button_url,
    button_log_event_name: "teacher.classdojo_school.click_get_started",
  };

  const CTASectionProps = {
    cta_title: "directus.common_data_schools.cta_title",
    cta_button_text: "directus.common_data_schools.cta_button_text",
    cta_button_url,
    cta_background_image_url: cta_background_image_url,
    cta_advice: "directus.common_data_schools.cta_advice",
    button_log_event_name: "teacher.classdojo_school.click_get_started",
  };

  return (
    <Box backgroundColor="#f5f5f3" overflow="auto">
      <SEO
        title="page_titles.pbis"
        description="page_descriptions.pbis"
        image="https://static.classdojo.com/img/page_pbis/pbis_fb.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "How to use ClassDojo with PBIS",
          description: "PBIS along with ClassDojo helps create a positive school culture — see how!",
          image: "https://static.classdojo.com/img/page_pbis/pbis_fb.jpg",
        }}
      />
      <SchoolsHeroSection {...SchoolsHeroProps} />
      <Container>
        <ItemBox>
          <MojoImg image={explaining} alt="Mojo" />
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={["100%", "100%", 10 / 12]}
            mx="auto"
            py={["30px", "50px", "50px"]}
            px={["15px", "70px", "70px"]}
          >
            <Title textAlign="center" size={1}>
              {t.translate("directus.page_pbis.pbis_features_title")}
            </Title>
            <Space size="l" />
            <PBISFeaturesList>
              {pbis_features_list.map((feature, idx) => (
                <>
                  <ListItemWrapper key={`feature_${idx + 1}`}>
                    <ListItemCheck
                      src={classdojo_school_check_url}
                      alt={t.translate("directus.common_data_schools.checked_alt") as string}
                    />
                    <ListItem title={t.translate(`directus.page_pbis.pbis_features_list.feature_${idx + 1}`)} />
                  </ListItemWrapper>
                  <Space size="m" />
                </>
              ))}
            </PBISFeaturesList>
          </Flex>
        </ItemBox>

        <ItemBox>
          <Flex flexWrap="wrap" flexDirection={["column-reverse", "row"]} height="100%">
            <Box
              width={["100%", 8 / 12]}
              py={["15px", "15px", "50px"]}
              px={["15px", "15px", "70px"]}
              css={itemBoxHeaderCss}
            >
              <MarkedTranslate path="directus.page_pbis.feature_one_text" />
            </Box>
            <Flex
              width={["100%", 4 / 12]}
              position="relative"
              my={["15px", 0]}
              justifyContent="center"
              minHeight={["auto", "auto", "483px"]}
            >
              <PhoneRightImg image={feature_one_image} alt="ClassDojo app on cellphone" />
            </Flex>
          </Flex>
        </ItemBox>

        <ItemBox>
          <Flex flexWrap="wrap" height="100%">
            <Flex
              width={["100%", 4 / 12]}
              position="relative"
              my={["15px", 0]}
              justifyContent="center"
              minHeight={["auto", "auto", "483px"]}
            >
              <PhoneLeftImg image={feature_two_image} alt="ClassDojo app on cellphone" />
            </Flex>
            <Box
              width={["100%", 8 / 12]}
              py={["15px", "15px", "50px"]}
              px={["15px", "15px", "70px"]}
              css={itemBoxHeaderCss}
            >
              <MarkedTranslate path="directus.page_pbis.feature_two_text" />
            </Box>
          </Flex>
        </ItemBox>

        <ItemBox>
          <Flex flexWrap="wrap" flexDirection={["column-reverse", "row"]} height="100%">
            <Box
              width={["100%", 8 / 12]}
              py={["15px", "15px", "50px"]}
              px={["15px", "15px", "70px"]}
              css={itemBoxHeaderCss}
            >
              <MarkedTranslate path="directus.page_pbis.feature_three_text" />
            </Box>
            <Flex
              width={["100%", 4 / 12]}
              position="relative"
              my={["15px", 0]}
              justifyContent="center"
              minHeight={["auto", "320px", "483px"]}
            >
              <PhoneRightImg image={feature_three_image} alt="ClassDojo app on cellphone" />
            </Flex>
          </Flex>
        </ItemBox>
      </Container>
      <CTASection {...CTASectionProps} />
    </Box>
  );
}

export default Pbis;
